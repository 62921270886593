.header
    display: flex
    color: #007FFD
    font-size: 48px
    line-height: 56px
    font-weight: 800
    padding-bottom: 24px

.content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: center
    margin-top: 40px

.layout
    display: flex
    align-items: center
    justify-content: center
    text-align: center

.img
    max-width: 200px

.text
    color: #031228
    font-size: 16px
    font-weight: 700
    line-height: 24px

    &Link
        color: #007FFD
        font-size: 16px
        font-weight: 700
        line-height: 24px
        text-decoration: none
        cursor: pointer
        padding-left: 5px

    &Bottom
        margin-bottom: 24px

    &Desc
        display: flex
        margin-bottom: 24px

.mobileText
    display: flex
    justify-content: center




